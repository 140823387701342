/*Colors:*/
/*black: #18191a*/
/*gray: #242526*/
/*light-gray: #3a3b3c*/
/*extra-light-gray: #616365*/
/*super-light-gray: #afb2b7*/
/*white: #e3e6ea*/
/*aqua: #7acbdd*/

body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  font-family: 'Noto Sans', sans-serif;
  background-color: #18191a;
  color: #e3e6ea;
  overflow-x: hidden;
}

.navbar {
  margin-bottom: 0;
  min-height: 8vh;
}

.nav-item {
  color: #e3e6ea;
}

.nav-item:hover {
  color: #7acbdd;
}

.info-container {
  color: #18191a;
}

#info-tabs {
  background-color: #eee;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

#info-tabs .nav.nav-tabs {
  margin-bottom: -10px;
}

.text-container {
  min-height: 65vh;
  padding: 30px 15px;
  margin-top: 10px;
  font-size: 18px;
  line-height: 2;
  text-align: center;
  background-color: #fff;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.text-container p {
  margin: 0 0 20px;
}

.text-container.small {
  padding: 30px 15px;
}

.navbar-brand .large {
  font-size: 26px;
  line-height: 40px;
}

.navbar-inverse .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .open > a:focus,
.navbar-inverse .navbar-nav > .open > a:hover {
  color: #afb2b7;
  background-color: #242526;
}

a.dropdown-toggle {
  margin: 0 0 18px -15px;
}

@media screen and (min-width: 768px) {
  .dropdown:hover .dropdown-menu {
    display: block;
  }
}

.scroll-up-button {
  height: 55px;
  width: 55px;
  border-radius: 30px;
  background-color: rgba(150, 150, 150, 0.4);
  border: 1px solid rgb(216, 216, 216);
  padding: 10px;
  position: fixed;
  right: 6%;
  bottom: 5%;
}
.scroll-up-button:active,
.scroll-up-button:focus {
  outline: none !important;
}

.scroll-up-icon {
  font-size: 33px;
  color: #27b3ca;
}

.column-padding-top {
  padding-top: 260px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-25 {
  margin-bottom: 25px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-all-5 {
  margin: 5px;
}

.margin-all-8 {
  margin: 8px;
}

.margin-auto {
  margin: 0 auto;
}

.padding-top-0 {
  padding-top: 0 !important;
}

.padding-left-0 {
  padding-left: 0 !important;
}

.padding-right-0 {
  padding-right: 0 !important;
}

.font-size-25 {
  font-size: 25px;
}

.font-size-20 {
  font-size: 20px;
}

.warn-input {
  border-color: maroon;
}

.warn-text {
  color: maroon !important;
}

.navbar .glyphicon {
  position: relative;
  top: 1px;
  left: 10px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.glyphicon-class {
  display: block;
  text-align: center;
  word-wrap: break-word;
  font-size: 14px;
}

.glyphicon-class.small {
  display: inline-block;
  position: relative;
  left: 20px;
  bottom: 4px;
  font-size: 14px;
}

.glyphicon-class.info {
  padding-left: 9px;
}

.viewer-header {
  margin-top: 0;
  font-weight: bold;
  color: #e3e6ea;
  font-size: 46px;
}

.thumbnail {
  margin-bottom: 0;
}

.thumbnail img {
  max-height: 100vh;
}

.photo-viewer-thumbnail {
  border: none;
  background-color: inherit;
  border-radius: 10px;
}

.photo-viewer-thumbnail img {
  border-radius: 10px;
}

.no-padding {
  padding-right: 0;
  padding-left: 0;
}

.background-color-single-thumbnail {
  background-color: #ddd;
}

.toggle-button-container {
  border: none;
}

.toggle-button-container.btn-default {
  background-color: #18191a;
  color: #e3e6ea;
}

.toggle-button-container:focus,
.toggle-button-container:active,
.toggle-button-container:hover {
  outline: none !important;
  box-shadow: none;
  background-color: #18191a !important;
  color: #e3e6ea !important;
}

.submit-button-color {
  border: #5dcee0 solid;
}

.view-more-button {
  border: #5dcee0 solid;
  background-color: #eee;
  margin: 0 auto 5px auto;
}

/*VIDEO PLAYER START*/

.video-player {
  height: 92vh !important;
}

.video-player video {
  background-color: #000;
}

/*VIDEO PLAYER END*/

/*ABOUT SECTION START*/

.about-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.about-section {
  max-width: 1124px;
  background-color: #242526;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 1px;
}

@media (max-width: 1124px) {
  .about-section {
    width: 100%;
  }
}

.about-header {
  font-size: 22px;
  margin: 10px;
}

.about-main {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  font-size: 16px;
  padding-bottom: 20px;
}

@media (max-width: 1124px) {
  .about-main {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
}

.about-main-column {
  width: 358px;
  min-height: 100px;
  margin: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.about-logo-label {
  font-size: 18px;
  margin: 4px;
}

/*ABOUT SECTION END*/

/*BEGIN VIEW MORE */

.view-more-container {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.view-more-section {
  max-width: 1124px;
  background-color: #242526;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  padding: 8px;
}

.view-more-header {
  font-size: 22px;
  margin: 10px;
}

.view-more-main {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.view-more-item {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  margin: 8px;
}

.view-more-image {
  width: 261px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
}

.view-more-image-label {
  margin: 6px;
  font-size: 20px;
}

/* END VIEW MORE*/

